
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        


















































































































































































































































































































































































































































































































































































































.algo-page {
  form {
    .form-group {
      h3 {
        padding: 0 1em;
      }
    }
    fieldset {
      background-color: var(--purple);
      color: var(--white-bg);
      border: none;
      border-radius: 2em;
      padding: 1.5em 1.5em 1.5em;

      legend {
        color: var(--gray-dark);
        text-align: center;
        display: block;
      }
      h4 {
        color: var(--white-bg);
        text-align: center;
        margin-top: 1.25em;
        font-size: 1em;
      }
    }

    input[type="checkbox"],
    input[type="radio"] {
      appearance: none;
      background-color: transparent;
      border: solid 2px var(--white-bg);
      border-radius: 50%;
      width: 1.5em;
      height: 1.5em;
      margin: 0em 0 0.75em 0;
    }

    input[type="checkbox"] + label,
    input[type="radio"] + label {
      display: block;
      margin: -2.5em 0 0 1.5em;
      padding-left: 0.75em;
      // text-indent: 1em;
    }

    input[type="checkbox"]:focus,
    input[type="radio"]:focus {
      outline: solid 1px var(--white-bg);
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      position: relative;
      background-color: var(--lime);
      border-color: var(--lime);
    }

    input[type="checkbox"]:checked:before,
    input[type="radio"]:checked:before {
      @include icon-font-styles;
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 0.9em;
      margin-top: 0.2em;
      margin-left: 0.1em;
      content: "\e90a";
      color: var(--purple);
    }

    input[type="radio"].red:checked:before {
      margin-top: 0.2em;
      margin-left: 0.2em;
      content: "\e90b";
      color: var(--purple);
    }

    input[type="radio"].red:checked {
      position: relative;
      background-color: var(--pink-light);
      border-color: var(--pink-light);
    }
  }

  #q-2 {
    .icon-magglass:before {
      color: var(--lime);
      font-size: 5em;
      display: block;
      margin: 0.25em auto;
      text-align: center;
    }
    fieldset {
      padding: 1em 1.5em 2em;
    }
    .center-inputs {
      display: block;
      width: 8em;
      margin: auto;
    }
  }

  .exame-opts {
    max-width: 300px;
    margin: 0 auto;
  }

  .result {
    background-color: var(--purple);
    color: var(--white-bg);
    border: none;
    border-radius: 2em;
    padding: 1.5em 1.5em 1.5em;
    margin: 0.5em 0;

    [class^="icon"].main:before {
      font-size: 5em;
      display: block;
      margin: 0.25em auto;
      text-align: center;
      color: var(--lime);
    }
    p[class^="icon"]:before {
      color: var(--lime);
      display: inline-block;
      font-size: 2em;
      margin-top: 0.5em;
      padding-right: 0.25em;
    }
    p {
      color: var(--white-bg);
      text-align: center;
    }
    h3 {
      text-align: center;
      color: var(--white-bg);
    }
  }
}
