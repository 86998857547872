/*************************************************
* CSS Animations
**************************************************/
@keyframes slideDown {
  from {transform: translateY(-100%); opacity: 0}
  to {transform: translateY(0); opacity: 1 }
}
@keyframes slideLeft {
  from {transform: translateX(-100%); opacity: 0}
  to {transform: translateY(0); opacity: 1 }
}
@keyframes slideRight {
  from {transform: translateX(100%); opacity: 0}
  to {transform: translateY(0); opacity: 1 }
}
@keyframes fade {
  from {opacity: 0}
  to {opacity: 1 }
}