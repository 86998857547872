
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        




















































footer {
  padding: 5em 0 calc(10px + env(safe-area-inset-bottom));
  text-align: center;
  font-size: 0.75em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    max-width: 75%;
    margin-right: -5em;
  }

  @media only screen and (min-width: $sm) {
    & {
      text-align: right;
      flex-direction: row;
    }

    .logo {
      text-align: left;
    }

    img {
      margin-right: 0;
    }
  }

  p {
    margin-bottom: 0.4em;
  }
  a {
    color: var(--gray);
  }
}
