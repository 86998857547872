/*************************************************
* Sass mixins, with repeated stuff
**************************************************/

@mixin bg-blur {
	background: linear-gradient(
	to top,
	RGBA(255, 255, 255, 0.1) -150%,
	RGBA(255, 255, 255, 0.1)
  );
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  backdrop-filter: blur(9px) brightness(1.15);
}

@mixin small-arrow {
	position: absolute;
	top: calc(50% - 1.25em / 2);
	right: -0.4em;
	content: "\e904";
	display: block;
	font-family: "icomoon";
	color: var(--blue);
	background-color: var(--white-bg);
	opacity: 0.9;
	width: 1.2em;
	height: 1.25em;
	text-align: center;
	line-height: 1.25em;
	border-radius: 5px;
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
	pointer-events: none;
}

@mixin icon-font-styles {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}