
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        

















































































.bottom-menu {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin: 0;
  padding-bottom: env(safe-area-inset-bottom);

  li {
    display: block;
    list-style-type: none;
    width: 100%;
    text-align: center;
    cursor: pointer;
    margin-left: -1.5em;
  }

  li:first-child {
    margin-left: 0em;
    label {
      text-indent: -0.75em;
    }
  }
  li:last-child label {
    text-indent: 0.75em;
  }

  a:before {
    display: block;
    font-size: 2em;
    padding-top: 0.25em;
    margin-bottom: 0.2em;
  }
  a {
    display: block;
    color: var(--white-bg);
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
    position: relative;
    font-size: 0.8em;

    span {
      position: relative;
      mix-blend-mode: multiply;
      // background-blend-mode: multiply;
      display: block;
      border: 2px solid var(--lime);
      background: var(--lime);
      border-radius: 2.5em;
      padding: 0.3em 0.5em;
      cursor: pointer;
      height: 2.25em;
    }
    label {
      cursor: pointer;
      position: absolute;
      bottom: 8%;
      width: 100%;
      display: block;
      z-index: 10;
    }

    &.theme-lime {
      &:before {
        color: var(--lime);
      }
      span {
        background: var(--lime);
        border-color: var(--lime);
      }
    }

    &.theme-pink {
      &:before {
        color: var(--pink);
      }
      span {
        background: var(--pink);
        border-color: var(--pink);
      }
    }

    &.theme-green {
      &:before {
        color: var(--green);
      }
      span {
        background: var(--green);
        border-color: var(--green);
      }
    }

    &.current-page,
    &.router-link-active {
      &.theme-lime {
        span {
          background: transparent;
        }
        label {
          color: var(--lime);
        }
      }
      &.theme-pink {
        span {
          background: transparent;
        }
        label {
          color: var(--pink);
        }
      }
      &.theme-green {
        span {
          background: transparent;
        }
        label {
          color: var(--green);
        }
      }
    }
  }

  //&.router-link-exact-active
}

@media only screen and (min-width: $sm) {
  .bottom-menu {
    a {
      span {
        // padding: 0.8em 0.5em;
        height: 2.25em;
      }
    }
  }
}

@media (min-width: $sm) and (max-height: 540px) and (orientation: landscape) {
}
