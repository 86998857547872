
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        




















































.prev-next-nav {
  display: flex;
  justify-content: space-between;

  .icon-arrow-right-after:after {
    content: "\e903";
  }

  a {
    text-decoration: none;

    &:after,
    &:before {
      font-size: 0.9em;
    }
  }
  .next {
    text-align: right;
  }
}
