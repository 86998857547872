
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        
































































































.home h1 {
  font-weight: 500;
  font-size: 2.2em;
  padding: 0 0.75em;
  max-width: 20ch;
  margin: 1em auto;
  text-transform: uppercase;
}

@media only screen and (min-width: $lg) {
  .home {
    // margin: 0 -250px;
    // margin: 0 -25% 0 -19%;
  }
  .hp-grid {
    display: flex;
    // flex:1 0 33%;
    flex-wrap: wrap;

    & > div:nth-child(1) {
      width: 80%;
      // margin-left: clamp(0px, 1%, 115px);
      margin: 0 auto;
    }

    & > div:nth-child(2) {
      // width: 25%;
      // margin-top: 6em;
      // background-color: #E7E9E4;
    }

    & > div:nth-child(3) {
      // max-width: 15%;
      // margin-top: 6em;
      // align-self: center;
      // background-color: #E7E9E4;
    }
  }
}
