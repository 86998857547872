
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        




















































.sub-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  li {
    padding: 1em;
    width: 15em;

    a {
      text-decoration: none;
      color: var(--gray-dark);
    }

    label {
      display: block;
      text-align: center;
      border: 2px solid var(--gray-dark);
      border-radius: 2.5em;
      padding: 0.5em 0.5em;
      margin: 0.5em 0;
      font-size: 0.8em;
      cursor: pointer;
      font-weight: 600;
      font-size: 1.25em;
    }
  }
}
