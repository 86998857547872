
          @import "@/css/_mixins.scss";
          @import "@/css/_vars.scss";
        









































































































.btns-2cols-md {
  display: flex;
  flex-wrap: wrap;

  & > * {
    width: 100%;
    a {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: $md) {
    & > * {
      width: 45%;
      margin: 0 2.5%;
    }
  }
}
