/*************************************************
* Utility classes
**************************************************/

/*************************************************
* text alignment
**************************************************/

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}


/*************************************************
* Hide classes
**************************************************/

.hide-xs {
  display: none !important;
}

@media only screen and (min-width: $sm) {
  .hide-xs {
    display: block !important;
  }
}


/*************************************************
* Margin/Padding classes
**************************************************/

$props: (
  "mb": 'margin-bottom',
  "mt": 'margin-top',
  "mr": 'margin-right',
  "ml": 'margin-left',
) !default;

$amts: 1, 2, 3, 4;

@each $short, $prop in $props {
  
  @each $amt in $amts {
    .#{$short}-#{$amt} {
      #{$prop}: #{$amt}em;
    }
  }

}

//both margins
$props: (
  "mx": 'margin',
) !default;

$amts: 1, 2, 3, 4;

@each $short, $prop in $props {
  
  @each $amt in $amts {
    .#{$short}-#{$amt} {
      #{$prop}: #{$amt}em;
    }
  }

}


/*************************************************
* CSS specific to iOS devices
**************************************************/

@supports (-webkit-touch-callout: none) {
  //remove shadow on ios (buggy with backdrop-filter)
  .Home .linha-apoio, .sub-menu li, .menu-receitas li, .menu-receitas .chef, .faq ul li, .page-contact .btn {
    box-shadow: none !important;
  }
}


/*************************************************
* prevent selection on element
**************************************************/

.no-select {
  // https://stackoverflow.com/a/4407335
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  -webkit-tap-highlight-color: rgba(255,255,255,0.25); //customize tap highlight color
}


/*************************************************
* remove arrows from number input type
**************************************************/

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


/*************************************************
* custom select text color
**************************************************/

::-moz-selection { /* Code for Firefox */
  color: var(--white);
  background: var(--blue-dark);
}

::selection {
  color: var(--white);
  background: var(--blue-dark);
}


/*************************************************
* image not available
**************************************************/
img { 
  /* Same as first example */
  // min-height: 50px;
  position: relative;
  overflow: hidden;
  color:var(--white-bg);
  // background-color: var(--blue);
}

img:before {

  content: " ";
  display: block;

  position: absolute;
  top: 0px;
  left: 0;
  bottom: 0;
  right: 0;
  // height: calc(100% + 10px);
  width: 100%;
  
  background-color: var(--pink);
  // background-image: url(./assets/background.png); 
  background-size: cover;
  background-position: center center;

  // border: 2px dotted rgb(200, 200, 200);
  // border-radius: 5px;
  border-radius: 12px;
}

img:after { 
  content: "imagem indisponível - " attr(alt);
  display: block;
  font-style: normal;
  font-size: 0.8em;
  color:var(--white-bg);
  
  position: absolute;
  top: 25%;
  left: 5%;
  right: 5%;
  width: 90%;
  text-align: center;
}