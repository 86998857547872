/*
 * ColonCheck PWA
 * Author: Miguel Santos
 */

/*************************************************
* Partials Import 
* _mixins.scss + _vars.scss - is imported globally in vueConfig
**************************************************/
@import "css/normalize";
@import "css/vars-css";
@import "css/utils";
@import "css/icons";
@import "css/animations";

/*************************************************
* Typography
**************************************************/

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400;1,600;1,700&display=swap');

body {
  font-size: $base-font-size;
  font-family: var(--main-font-family);
  background-color: var(--white);
  color: var(--gray-dark);
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // direction: rtl;
}
@media only screen and (min-width: $sm) {
  body {
    font-size: 1.1em;
    line-height: 1.5;
  }
}

/***************** headings **********************/
$headings: "h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 "; 

#{$headings} {
  font-family: var(--main-font-family);
  font-style: normal;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0;
  color: var(--gray-dark);

  margin: 2rem 0 0;
  
  &.light {
    color:var(--white-bg);
  }

}

$themes: "lime", "pink", "green";

@each $theme in $themes {
  .#{$theme} h2 {
    color: var(--#{$theme}-dark);
  }
}

.light {
  #{$headings}, p {
    color:var(--white-bg);
  }
}

h1, .h1 {
  font-size: $heading-font-size + 1.35;
  letter-spacing: -0.08em;
  line-height: 1;
}
h2, .h2 {
  //36
  font-size: $heading-font-size + 1.25;
  font-weight: 300;
}
h3, .h3, .subheader {
  font-weight: 600;
  //24
  font-size: $heading-font-size + 0.75;
}
h4, .h4 {
  font-weight: 600;
  //16
  font-size: $heading-font-size + 0.25;
}
h5, .h5 {
  font-weight: 600;
  //14>16
  font-size: $heading-font-size + 0;
}
h6, .h6 {
  font-weight: 600;
  //12>16
  font-size: $heading-font-size + 0;
}


//general title subtitle styles
header {
  padding: 0.25em 0 0.5em;
  .title {
    animation: 0.8s ease-out 0s forwards slideDown;
  }
  .title,
  .sub-title {
    text-align: center;
    // top: 0.5em;
    z-index: 10;
  }
  .sub-title {
    margin: 0 0 1em;
    font-weight: 400;
    // top:1.8em;
    animation: 0.8s ease-in-out 0s forwards slideDown;
  }
}

/**************** body text *****************/
p {
  color: var(--gray-dark);
  font-size: 1em;
  margin: 0.25rem 0 1.5rem
}

p.lead {
  font-size: 1.1em;
}


/****************** Links *******************/
a {
  color: var(--pink);
  transition: color 0.5s;

  &:hover {
    color: var(--pink-light);
  }
}

/****************** Lists *******************/
ul, ol { width: auto; overflow: hidden; }

.content li {
  line-height: 1.2em;
  padding-bottom: 0.75em;
}

.red-flags {
  position: relative;
  overflow: visible;
  border: solid 6px var(--lime);
  border-radius: 12px;
  padding:1.5em 0.5em 0.5em 2em;
  width: 100%;

  &:before {
    @include icon-font-styles;
    content: "\e90c";
    position: absolute;
    top: -0.4em;
    right: 0.1em;
    font-size: 4em;
    color: var(--pink);
  }
  li::marker {
    @include icon-font-styles;
    color: var(--pink) 
  }
  li {
    list-style-type: "\e90a";
    padding-inline-start: 2ch;
    margin: 0.5em;
    font-weight: 600;
  }
}

.checklist {
  position: relative;
  overflow: visible;
  border-radius: 12px;
  padding:0.5em 0.5em 0.5em 2em;
  width: 100%;

  li::marker {
    @include icon-font-styles;
    color: var(--green);
  }
  li {
    list-style-type: "\e90a";
    padding-inline-start: 2ch;
    margin: 0.5em;
    font-weight: 600;
  }

  &.weight-normal li {
    font-weight: normal;
  }
}
.cross.pink {
  li::marker {
    @include icon-font-styles;
    color: var(--pink) 
  }
  li {
    list-style-type: "\e90b";
    padding-inline-start: 2ch;
    margin: 0.5em;
  }
}

.check.green {
  li::marker {
    @include icon-font-styles;
    color: var(--green);
  }
  li {
    list-style-type: "\e90a";
    padding-inline-start: 2ch;
    margin: 0.5em;
  }
}

/****************** tables *******************/
table {
  &.colored {
    border-spacing: 0 0.5em;
    color: var(--white);
    font-size: 0.9em;

    p {
      color: var(--white);
    }

    tr {
    }

    th {
      color: var(--gray-dark);
      text-align: left;
      padding: 0.75em 0.5em;
    }

    &.purple th {
      color: var(--lime-dark);
      font-size: 1.2em;

      span[class^="icon-"]:before {
        font-size: 1.5em;
        padding-right: 0.25em;
        vertical-align: middle;
      }
    }

    td {
      // border-bottom: solid 0.5em var(--white);
      padding: 0.75em 0.5em;
    }

    &.pink td {
      background-color: var(--pink);
    }
    &.purple td {
      background-color: var(--purple);
    }

    &.pink tr:nth-child(odd) td {
      background-color: var(--pink-light);
    }

    &.purple tr:nth-child(odd) td {
      background-color: var(--purple-light);
    }

    td:first-child {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    td:last-child {    
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
    }
  }
}

table.bristol {

  border-spacing: 0.5em 1.5em;

  td:nth-child(1) {
    width: 25%;
  }
  td:nth-child(2) {
    width: 25%;
    vertical-align: top;
    text-align: center;
    font-weight: bold;
    color:var(--lime);

  }
  td:nth-child(3) {
    width: 50%;
    font-size: 0.9em;
  }

}

/*************** Highlights *****************/
.pink-hl {
  color: var(--pink);
}

.lime-hl {
  color: var(--lime);
}

.green-hl {
  color: var(--green);
}

/*************** quotes *****************/
blockquote {
  border: 5px solid var(--lime);
  border-radius: 12px;
  margin: 1.5em 0;
  padding: 1em;
  // text-align: center;
  font-weight: 600;
}

blockquote.green-corners {
  position: relative;
  color: var(--green);
  border: none;
  padding:1em 2em;
  font-weight: 400;
}
blockquote.green-corners:before {
  content: " ";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5em;
  height: 1.5em;
  border-top: 5px solid var(--green);
  border-left: 5px solid var(--green);
}

blockquote.green-corners:after {
  content: " ";
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 1.5em;
  height: 1.5em;
  border-bottom: 5px solid var(--green);
  border-right: 5px solid var(--green);
}


/*************************************************
* styles for pages
**************************************************/
.page {
  //icons
  h4[class^="icon-"]:before {
    display: block;
    // margin: 0 auto;
    // text-align: center;
    font-size: 3.5em;
    color: var(--blue)
  }
  h4.text-center[class^="icon-"]:before {
     text-align: center;
  }

  .divider.lime[class^="icon-"]:before {
    display: block;
    color: var(--lime);
    text-align: center;
    width: 100%;
    font-size: 5em;
  }
}

.fw-bgcolor {
  background-color: #E7E9E4;
  margin: 0 -5.25%;
  padding: 1em 5% 1em;
  text-align: center;

  img {
    max-width: 200px;
  }
}

.featured-image {
   margin: 0 10%;
}

picture.featured {
  display: block;
  position: relative;
  width: 100%;
  padding-top: 100%;


  &.no-round {
    padding-top: 120%;

    img {
      border-radius: 0;
      object-fit: contain;
    }
  }

  img {
    position: absolute;
    top: 0;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}


/****** special titles ******/
h4.green {
  color: var(--green);
  font-size: 1.75em;
  padding-bottom: 1.5em;
}
.pill-pink,
.pill-green {
  position: relative;
  color: var(--white);
  background-color: var(--green);
  display: inline-block;
  padding: 0.35em 1em 0.35em 2.25em;
  border-radius: 5em;
  font-size: 1.1em;
  text-transform: uppercase;
}

.pill-pink {
  background-color: var(--pink);
}

[class^="pill-"]:before {
  position: absolute;
  font-size: 1.3em;
  top: 0.25em;
  left: 0.25em;
}


// icon-cross

/****** Gallery ******/
.page {

  .gallery-icons {

    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;

    ul { padding-left: 1.5em; }
    li,p {
      text-align: left;
    }
    img + p {
      text-align: center;
    }

    & > div {

      width: 100%;
      margin-bottom: 1em;

      @media only screen and (min-width: $sm) {
        width: 25%;
      }

      span {
        display: block;
        padding-bottom: 0.5em;
      }
    }

    &.md-list {
      @media only screen and (min-width: $sm) {
        & > div {
          width: 100%;
        }
        img + p {
          text-align: left;
        }
        img {float: left; padding-right: 1.5em}
      }
    }
  }

  .refs {
    font-size: 0.75em;

    hr {
      margin-top: 3.5em;
    }

    ul,ol { 
      padding-left: 1.25em;
      padding-top: 0;
      padding-bottom: 0.5em;
    }
    p {
      margin-bottom: 0;
    }

  }


  .image-title {
    text-align: center;
    padding: 1em 1em 0;
  }
  .logo-center {
    display: block;
    margin:1em auto;
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    &.align-center {
      align-items: center;
    }

    & > * {
      width: 100%;
      object-fit: contain;
      padding: 1em;

    }
    
    $cols: 1, 2, 3, 4;

    @each $col in $cols {
      &.sm-#{$col}cols > * {
        width: #{100% / $col};
      }
    }
    
    // generated output * numcols
    // &.sm-1col > * {
    //   width: 100%;
    // }

    @media only screen and (min-width: $sm) {
      
      @each $col in $cols {
        &.md-#{$col}cols > * {
          width: #{100% / $col};
        }
      }

    }
    @media only screen and (min-width: $md) {
      
      @each $col in $cols {
        &.lg-#{$col}cols > * {
          width: #{100% / $col};
        }
      }

    }
  }
}

/*************************************************
* Layout
**************************************************/
.content {
  padding: 10px 0 calc(15px + env(safe-area-inset-bottom));
  margin: 0 auto;
  width: 85%;
  max-width: 960px; //was 1200
}
@media only screen and (min-width: $sm) {
  .content {
    padding: 15px 50px 15px 50px;
  }
}

#app {
  min-height: 100vh;
  transition: background-position 0.5s ease-in-out;
  background-image: url(./assets/bg/bg_lime_left.svg), url(./assets/bg/bg_grey_right.svg);
  background-position: -5px 160px, calc(100% + 5px) 160px;
  background-size: 15%;
  background-color: #fafafa;
  // background-attachment: fixed;
  background-repeat: no-repeat;
  // background-position:center 120vh;

  /* @media only screen and (min-width: $sm) {
    background-position: -20px top, calc(100% + 20px) top;
  }

  @media only screen and (min-width: $lg) {
    &.Home {
      background-image: 
        url(./assets/bg/bg_lime_left.svg), 
        url(./assets/bg/bg_grey_right.svg), 
        linear-gradient(to right, #fafafa 0%, #fafafa 67%, #E7E9E4 67%, #E7E9E4 100%);
      background-position: -20px top, calc(60% + 20px) top, 0;
      background-size: 15%, 15%, 100%;

    }
  } */
}

 #app.transitioning {
  background-position: -200px top, calc(100% + 200px) top, 0;
  @media only screen and (min-width: $lg) {
    background-position: -300px top, calc(100% + 300px) top, 0;
  }

  &.prev-null {
    background-image: url(./assets/bg/bg_lime_left.svg), url(./assets/bg/bg_grey_right.svg);
  }

  &.prev-a-sindrome {
    background-image: url(./assets/bg/bg_lime_left.svg), url(./assets/bg/bg_lime_right.svg);
  }

  &.prev-diagnostico {
    background-image: url(./assets/bg/bg_pink_left.svg), url(./assets/bg/bg_pink_right.svg);
  }

  &.prev-tratamento {
    background-image: url(./assets/bg/bg_green_left.svg), url(./assets/bg/bg_green_right.svg);
  }
}

/* Backgrounds for submenu pages (.Home,) */
#app.a-sindrome {
  background-image: url(./assets/bg/bg_lime_left.svg), url(./assets/bg/bg_lime_right.svg);
}

#app.diagnostico {
  background-image: url(./assets/bg/bg_pink_left.svg), url(./assets/bg/bg_pink_right.svg);
}

#app.tratamento {
  background-image: url(./assets/bg/bg_green_left.svg), url(./assets/bg/bg_green_right.svg);
}

/*************************************************
* TOPBAR 
**************************************************/
#top-bar {
  padding-top: env(safe-area-inset-top, 0); left: 0;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 1.25em 2.5% 0.75em;
  width: 100%;

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
    div {
      padding-top: 0.5em;
      color: var(--gray-dark);
      font-weight: 600;
    }
    img.logo {
      max-width: 70px;
    }
  }
}

@media only screen and (min-width: $sm) {
  #top-bar {
    flex-flow: row wrap;
    justify-content: space-between;

  }

}

/*************************************************
* BOTTOM NAV
**************************************************/

nav {
  width: 100%;
}


@media only screen and (min-width: $sm) {
  nav {
    // position: fixed;
    // top: 0;
    // left:0;
    // width: calc(120px + env(safe-area-inset-left));
    width: 65%;
  }
}

/*************************************************
* FORMS
**************************************************/

button, .button {
  display: block;
  appearance: none;
  border: none;
  background-color: var(--pink);
  color: var(--white-bg);
  border-radius: 5em;
  padding: 0.85em 1.5em;
  margin:1em auto;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase; 
  &:hover {
    color: var(--white-bg);
  }
}



button.outline, .button.outline, .button.router-link-active {
  background-color: transparent;
  color: var(--pink);
  border: solid 0.15em var(--pink);
  padding: 0.75em 1.5em;
}

button.purple, .button.purple {
  background-color: var(--purple);
}

button.inline-b, .button.inline-b {
  display: inline-block;
}

button.icon, .button.icon {
  padding-top: 0.5em;
  svg {
    width: 1.35em;
    margin-bottom: -0.25em;
    margin-right: 0.25em;
  }
}
button.sm, .button.sm {
  font-size: 0.8em;
}
/*************************************************
* ANIMATIONS
**************************************************/

.transitioning {
  overflow: hidden;
}

.slide-enter-active {
  animation: slideRight 0.4s;
}

.slide-leave-active {
  animation: slideLeft 0.2s reverse;
}

.slide-back-enter-active {
  animation: slideLeft 0.4s;
}

.slide-back-leave-active {
  animation: slideRight 0.2s reverse;
}

.slide-down-enter-active {
  animation: slideDown 1s;
}

.slide-down-leave-active {
  animation: slideDown 0.6s reverse;
}

.fade-enter-active {
  animation: fade 0.4s;
}

.fade-leave-active {
  animation: fade 0.3s reverse;
}


