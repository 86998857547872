/*************************************************
* Root Element vars
**************************************************/

/* Root Element vars */
:root {
	/* Colors: */
	// --blue: #0A6BB7;
	// --dark-gray: #646464;
	// --medium-gray: #969696;
	// --light-gray: #C8C8C8;
	// --white: #FFFFFF;

	@each $color, $value in $colors-light {
    --#{$color}: #{$value};
  }

  /*Media Queries*/
  @each $bp, $value in $media-queries {
    --#{$bp}: #{$value};
  }

	/* Font/text values */
	--main-font-family: 'Open Sans', Helvetica, Segoe UI, Arial, sans-serif;
}

[data-theme="dark"] {
	@each $color, $value in $colors-dark {
    	--#{$color}: #{$value};
  	}
}

// @media (prefers-color-scheme: dark) {
//   @each $color, $value in $colors-dark {
//     --#{$color}: #{$value};
//   }
// }